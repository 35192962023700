import {
    getSetInfoByAreaId,//根据解决方案获取分区
    getAreaInfoById,//根据解决方案获取分区
    getSolution
} from "@/api/dataSet/solution.js";
import Swiper from "swiper";
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储备行业',
            //                     sort: 1
            //                 }, {
            //                     descName: 'LNG是通过在常压下气态的天然气，经过预处理，脱除重烃、硫化物、氧化碳和水等杂质后，冷却至-162°C，使之凝结成液体。LNG无色无毒无味，是由天然气转变的另一种能源形式，常温下会迅速气化，不会爆炸，也没有污染，是一种清洁、高效的能源。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储罐',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储槽',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: 'LNG储罐管道',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.png'),
            //             phoneImg: require('./images/mobile/banner5.png'),
            //             descList: [
            //                 {
            //                     descName: '表计识别读数',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '查看卸车台液相、气相管的压力表显示是否处于正常范围',
            //                     sort:2
            //                 }],
            //              type: 'image',
            //             sort:5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.png'),
            //             phoneImg: require('./images/mobile/banner6.png'),
            //             descList: [
            //                 {
            //                     descName: '图像异常识别',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '在LNG槽车装卸后，人工查看卸车软管有无结霜现象',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort:6
            //         },   {
            //             pcImg: require('./images/pc/banner7.png'),
            //             phoneImg: require('./images/mobile/banner7.png'),
            //             descList: [
            //                 {
            //                     descName: '表计识别读数',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '查看卸车台各个阀门处于指定开、关位置',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort:7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.png'),
            //             phoneImg: require('./images/mobile/banner8.png'),
            //             descList: [
            //                 {
            //                     descName: '跑冒滴漏监测',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '各工艺管道和设备有无漏气现象',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort:8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '图像识别功能读取表计读数、阀门开关状态',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort:9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '通过AI视觉识别技术等检测手段进行跑冒滴漏检测',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort:10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '通过图像建模识别技术和机器深度学习算法，机器人可以准确识别现场卸车软管有无结霜现象，对设备的运行安全进行全方位的记录',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort:11
            //         },
            //     ],
            areaObj: {
                solutionPcImg: null,
            },
            areaDetailObj: {},
            solutionList: [],//解决方案数据
            aboutswiper: null,
            randomList: [],
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        this.getSolution();
    },
    methods: {
        // 获取解决方案
        async getSolution() {
            let res = await getSolution(0);
            if (res.code === 200) {
                if (res.data.length) {
                    // 组装一个特殊的‘固定式云台’解决方案
                    res.data.push({
                        solutionId: 999,
                        solutionName: "SAAS系统服务方案",
                        solutionDescription: "",
                        solutionPcImg: require("../PTZsolution/images/pc/PTZ.jpg"),
                        solutionPhoneImg: require("../PTZsolution/images/mobile/PTZ.jpg"),
                    });
                    this.randomList = this.randomNums(0, res.data.length-1, 3);
                    this.solutionList = res.data.filter(i => i.solutionId != this.areaObj.solutionId)
                    //    生成三个随机数，随机显示解决方案
                    this.getSetInfoByAreaId();
                    setTimeout(() => {
                        this.aboutSwiper();
                    }, 100);
                }
            }
        },

        async getSetInfoByAreaId() {
            let res = await getSetInfoByAreaId(this.$route.query.areaId);
            if (res.code === 200) {
                this.areaDetailObj = res.data;
                this.getAreaInfoById(this.areaDetailObj.solutionId);
            }
        },

        async getAreaInfoById(solutionId) {
            let res = await getAreaInfoById(solutionId);
            if (res.code === 200) {
                this.areaObj = res.data;
            }
        },

        //取得[n,m]范围随机数
        //min:最小值   max:最大值    count:生成的数量
        randomNums(min, max, count) {
            let arr = []
            let i = 0
            for (i; i < count; i++) {
                let num = this.randomNum(min, max)
                if (arr.indexOf(num) == -1) {
                    arr[i] = num
                } else {
                    i--
                }
            }
            return arr
        },
        randomNum(min, max) {
            let num = Math.floor(((Math.random() * (max - min + 1)) + min))
            return num
        },
        // 初始化轮播图
        aboutSwiper() {
            this.aboutswiper = new Swiper(".mySwiper", {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: "fade", //切换效果
                fadeEffect: {
                    slideShadows: true,
                    limitRotation: true,
                    crossFade: true, //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
        solutiondetails(item) {
            this.$router.push({
                path: '/solutionArea',
                query: {
                    solutionId: item.solutionId
                }
            });
            // location.reload();
        }
    },
}